.footer {
    position: relative;
    width: 100%;
    background-color: #3586ff;
    padding: 20px 50px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
}
.wave{
    position: absolute;
    top: -100px;
    left: 0;
    background:url(../../Assets/wave.png);
    width: 100%;
    height: 100px;
    background-size: 1000px 100px;
}
.wave#wave1{
    z-index: 99;
    opacity: 1;
    bottom: 0;
    animation: animateWave 4s linear infinite;
}
.wave#wave2{
    z-index: 98;
    opacity: 0.5;
    bottom: 10px;
    animation: animateWave_02 4s linear infinite;
}
.wave#wave3{
    z-index: 100;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWave 3s linear infinite;
}
.wave#wave4{
    z-index: 98;
    opacity: 0.7;
    bottom: 20px;
    animation: animateWave_02 3s linear infinite;
}
@keyframes animateWave {
    0%{background-position-x: 1000px;}
    100%{background-position-x: 0px;}
}
@keyframes animateWave_02 {
    0%{background-position-x: 0px;}
    100%{background-position-x: 1000px;}
}
.footer-wrapper{
    background: url(../../Assets/vip.gif);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}