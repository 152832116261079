@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
}

.btn {
    color: #830e72;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    background: #fff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
    border-radius: 35px;
    padding: 8px 20px;
    cursor: pointer;
}
.animatebtn {
    position: relative;
    width: 200px;
    padding: 13px 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 2px;
    border: 2px solid #0f0;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    background-color: #fff;
    color: #111;
    -webkit-box-reflect: below 0px linear-gradient(transparent, #0002);
    transition: 0.5s ease;
    transition-delay: 0s;
}

.animatebtn>span {
    position: relative;
    z-index: 20;
}

.animatebtn:hover,
.animatebtn>span:hover {
    transition-delay: 0.7s;
    color: #fff;
    cursor: pointer;
}

.animatebtn::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    transform: translateY(-50%);
    background-color: #0f0;
    left: -20px;
    top: 50%;
    box-shadow: 5px -8px 0px #0f0, 5px 8px 0px #0f0;
    transition: width 0.5s, left 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 0.7s, 0.2s, 0s, 0s;
}

.animatebtn:hover::before {
    width: 60%;
    height: 100%;
    left: -2px;
    box-shadow: 5px 0px 0px #0f0, 5px 0px 0px #0f0;
    transition-delay: 0s, 0.2s, 0.7s, 0.7s;
}

.animatebtn::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    transform: translateY(-50%);
    background-color: #0f0;
    right: -20px;
    top: 50%;
    box-shadow: -5px -8px 0px #0f0, -5px 8px 0px #0f0;
    transition: width 0.5s, right 0.5s, height 0.5s, box-shadow 0.5s;
    transition-delay: 0.7s, 0.2s, 0s, 0s;

}

.animatebtn:hover::after {
    width: 60%;
    height: 100%;
    right: -2px;
    box-shadow: -5px 0px 0px #0f0, -5px 0px 0px #0f0;
    transition-delay: 0s, 0.2s, 0.7s, 0.7s;

}