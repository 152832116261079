.navbar-wrapper {
    width: 100%;
}

.navbar-top {
    background-color: #F2F5FA;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
}

.navbar-top>p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #414A4D;
    margin-right: 20px;
}

.navbar-bottom-wrapper {
    width: 100%;
    background-color: white;
    box-shadow: 0 1px 20px 0 rgb(0 0 0 / 14%);
    z-index: 1;
    position: relative;
    background: #fff;
    transition: 0.5s;
    position: relative;
}
.navbar-fixed{
    position: fixed;
    top: 0;
    transition: 0.5s;
    z-index: 99;
}
.navbar-bottom {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.navbar-logo {
    width: 20%;
    text-transform: uppercase;
    padding: 0px 20px;
}
.navbar-logo>img{
    width: 100%;
    height: 100%;
}

.navbar-menu {
    width: 80%;
    display: flex;
    justify-content: right;
}
.navbar-menu>ul{
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;  
    margin-right: 20px; 
}
.navbar-menu>ul>li ,
.navbar-menu>ul>a>li{
    padding: 0 12px;
    color: #595959 ;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    cursor: pointer;
}
